import axios from 'axios'
import { ElMessage, ElLoading } from 'element-plus'

let loading = null

// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL, // api的base_url
  timeout: 15000, // 请求超时时间
})

// request拦截器
service.interceptors.request.use(
  (config) => {
    // loading = Loading.service({
    //   text: '加载中……',
    //   background: 'rgba(255, 255, 255, 0.7)',
    // })
    loading = ElLoading.service({
      text: '加载中……',
      background: 'rgba(255, 255, 255, 0.7)',
    })

    return config
  },
  (error) => {
    // Do something with request error
    console.log(error) // for debug
    Promise.reject(error)
  }
)

// respone拦截器
service.interceptors.response.use(
  (response) => {
    if (loading) {
      loading.close()
    }
    /**
     * code为非200是抛错 可结合自己业务进行修改
     */
    const res = response.data
    if (res.Code !== '1000') {
      ElMessage({
        message: res.Message,
        grouping: true,
        type: 'error',
        duration: 3 * 1000,
      })
    }
    return response.data
  },
  (error) => {
    if (loading) {
      loading.close()
    }
    console.log('err' + error) // for debug
    ElMessage({
      message: error.message,
      grouping: true,
      type: 'error',
      duration: 3 * 1000,
    })
    return Promise.reject(error)
  }
)

export function downloadFile(url, params = {}) {
  return new Promise((resolve, reject) => {
    service
      .get(url, { params, timeout: 300000, responseType: 'blob' })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export default service
