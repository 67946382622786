import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import AMapLoader from '@amap/amap-jsapi-loader'
// 秘钥配置
window._AMapSecurityConfig = {
  // 官方推荐将秘钥存储在服务器中 安全性更高
  // serviceHost:'http://host:port/_AMapService',
  securityJsCode: '79416c00e16184a534c1cc92756528e3',
}
AMapLoader.load({
  key: 'dccdffea006f69ec685caaa6d62ac657',
  // JSAPI 版本
  version: '2.0',
  // 需要的插件列表
  plugins: ['AMap.AutoComplete', 'AMap.PlaceSearch'],
  AMapUI: {
    version: '1.1',
    plugins: [],
  },
})
  .then((AMap) => {
    const app = createApp(App)
    app.use(router)
    app.config.globalProperties.$AMap = AMap
    app.use(ElementPlus)
    app.mount('#app')
  })
  .catch((e) => {
    console.log(e)
  })
