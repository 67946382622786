<template>
  <div>
    <h1>About Page</h1>
    <router-link to="/">Go to Home</router-link>
  </div>
</template>

<script>
export default {
  name: 'about-component',
};
</script>

<style scoped>
/* 你的样式 */
</style>
